import request from '@/api/request'

// 获取app下载列表
export const getAppDownloadList = (data: any) => {
    return request({
        url: '/pagedDomainCfg/pageInfo',
        method: 'get',
        params: data
    })
}

// 获取下载地址并上报
export const getDownloadUrl = (data: any) => {
    return request({
        url: '/pagedDownLog/getDownUrl',
        method: 'get',
        params: data
    })
}
